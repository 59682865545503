<!--
 * @Author: yangliao
 * @Date: 2020-10-22 08:25:10
 * @LastEditTime: 2020-12-29 11:27:17
 * @LastEditors: Please set LastEditors
 * @Description: 水表复装
 * @FilePath: /netHallOfficialAccounts/src/views/Business/WaterResume/WaterResume.vue
-->
<template>
  <div>
    <div>
      <business-status
        v-if="statusInfor"
        :statusInfor="statusInfor"
        :busHandlingId="busHandlingId"
      />
      <customers-news
        v-if="businessNews"
        ref="cuNews"
        :customersNews="customersNewsData"
        :businessState="businessState"
        :stateBoolean="isWuserNoFlag"
      />
    </div>
    <div class="vant-parser">
      <parser
        v-if="formConf"
        ref="parser"
        :key="key2"
        :form-conf="formConf"
        @submit="sumbitForm1"
      />
    </div>
    <!-- [nh_00]办理中状态显示 -->
    <div class="footer" v-if="this.businessState === 'see' && stateBoolean">
      <div class="btns">
        <van-button plain type="info" @click="showCancelReport" style="margin-right: 10px">
          取消办理
        </van-button>
        <van-button plain type="info" @click="updateInfo">修改信息</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';
import CustomersNews from '../components/CustomersNews';
import Parser from '@/components/vant-parser/Parser';
import {
  addBusHandling,
  selectWaterUserRel,
  selectBusHandlingById,
  updateBusHandling,
  cancelReport,
  selectHandUser,
  isMaster,
} from '@/api/business';
import { selectMaxRelease } from '@/api/feedback';
import BusinessStatus from '../components/BusinessStatus';
export default {
  components: {
    CustomersNews,
    Parser,
    BusinessStatus,
  },
  data() {
    return {
      key2: +new Date(),
      formConf: null,
      businessCode: null,
      moduleCode: null,
      custReadingId: null,
      busHandlingId: null,
      /* businessState: add-新增；see-查看；edit-修改 */
      businessState: null,
      /* 页面修改，取消 */
      stateBoolean: false,
      formReleaseId: null,
      /* 表卡信息 */
      accountNo: null,
      address: {
        areaInfor: '',
        fullAddressInfor: '',
      },
      /* 业务办理信息 */
      statusInfor: null,
      customersNewsData: {},
      isWuserNoFlag: null, // 是不是户主
    };
  },
  computed: {
    ...mapState('user', {
      homeInfo: (state) => state.homeInfo,
      businessNews: (state) => state.businessNews,
    }),
  },
  mounted() {
    this.into();
  },
  methods: {
    /* 页面初始化 */
    into() {
      if (this.businessNews && this.businessNews.moduleCode) {
        this.businessCode = this.businessNews.businessCode;
        this.moduleCode = this.businessNews.moduleCode;
        this.custReadingId = this.businessNews.custReadingId;
        this.businessState = this.businessNews.businessState;
        this.busHandlingId =
          this.businessNews.busHandlingId !== undefined ? this.businessNews.busHandlingId : null;
        this.accountNo = this.businessNews.accountNo;
        this.address.areaInfor = this.businessNews.accountAddress;
        this.selectMaxRelease();
        if (this.businessState === 'add') {
          this.isMaster();
          this.selectHandUser();
        }
        if (this.businessNews.moduleName !== undefined && this.businessNews.moduleName) {
          document.title = this.businessNews.moduleName;
        } else {
          document.title = '水表复装';
        }
      } else {
        Toast('获取业务类型信息失败，请返回重新选择！');
      }
    },
    /* 页面提交 */
    sumbitForm1(data) {
      let cuNews = this.$refs.cuNews.customersData();
      let formData = { ...data, ...cuNews };
      if (this.businessState === 'add') {
        let submitArg = {
          businessCode: this.businessCode,
          moduleCode: this.moduleCode,
          custReadingId: this.custReadingId,
          isReading: true,
          formReleaseIds: this.formReleaseId,
          formContent: JSON.stringify(formData),
          verification: {
            wuserNo: this.accountNo,
            moduleCode: this.moduleCode,
          },
        };
        this.addBusHandling(submitArg);
      } else {
        let updateArg = {
          busHandlingId: this.busHandlingId,
          formReleaseIds: this.formReleaseId,
          formContent: JSON.stringify(formData),
          verification: {
            wuserNo: this.accountNo,
            moduleCode: this.moduleCode,
          },
        };
        this.updateBusHandling(updateArg);
      }
    },
    /* 户号、办理人信息 */
    async selectHandUser() {
      let waterUserNo = this.accountNo;
      const { status, resultData } = await selectHandUser(waterUserNo);
      if (status === 'complete') {
        this.customersNewsData = resultData;
      }
    },
    /* 判断是不是户主本人*/
    async isMaster() {
      let wuserNo = this.accountNo;
      if (wuserNo) {
        const { status, resultData } = await isMaster(wuserNo);
        if (status === 'complete') {
          this.isWuserNoFlag = resultData;
        }
      }
    },
    // 取消
    showCancelReport() {
      Dialog.confirm({
        message: '您确定取消申请吗？',
        confirmButtonText: '取消申请',
        confirmButtonColor: '#3C7EF0',
        cancelButtonText: '点错了',
        cancelButtonColor: '#767E8C',
      })
        .then(() => {
          this.cancelReport();
        })
        .catch(() => {
          console.log('取消申请');
        });
    },
    /* 取消 */
    async cancelReport() {
      let arg = this.busHandlingId;
      const { status, resultData } = await cancelReport(arg);
      if (status === 'complete') {
        Toast('该业务已取消');
        // 回显页面
        this.setBusinessNews('see');
        this.key2 = +new Date();
        this.formConf = { ...this.formConf, readonly: true };
        this.selectBusHandlingById(this.busHandlingId);
      }
    },
    /* 修改信息 */
    async updateInfo(data) {
      this.setBusinessNews('edit');
      this.key2 = +new Date();
      this.formConf = { ...this.formConf, readonly: false };
      this.selectBusHandlingById(this.busHandlingId);
    },
    /* 获取表单样式 */
    async selectMaxRelease() {
      let arg = {
        businessCode: this.businessCode,
        moduleCode: this.moduleCode,
      };
      const { status, resultData } = await selectMaxRelease(arg);
      if (status === 'complete' && resultData) {
        this.formReleaseId = resultData.formReleaseId;
        let data = JSON.parse(resultData.content);
        if (this.businessState === 'see') {
          data = { ...data, readonly: true };
        }
        this.formConf = data;
        if (this.busHandlingId) {
          this.selectBusHandlingById(this.busHandlingId);
        }
      }
    },
    /* 数据查询 */
    async selectBusHandlingById(id) {
      const { status, resultData } = await selectBusHandlingById(id);
      if (status === 'complete') {
        this.statusInfor = resultData;
        this.stateBoolean = resultData.stateBoolean;
        let data = JSON.parse(resultData.formContent);
        // 表卡信息
        this.customersNewsData = {
          handUserVO: data.handUserVO,
          waterUserVO: data.waterUserVO,
        };
        if (data.handUserVO && Object.keys(data.handUserVO).length > 0 && data.handUserVO.wuserNo) {
          this.accountNo = data.handUserVO && data.handUserVO.wuserNo;
        } else {
          this.accountNo = data.waterUserVO && data.waterUserVO.wuserNo;
        }
        this.isMaster();
        this.$nextTick(() => {
          this.$refs.parser.fillForm(data);
        });
      } else {
        this.statusInfor = null;
      }
    },
    /* 新增提交 */
    async addBusHandling(data) {
      const { status, resultData } = await addBusHandling(data);
      if (status === 'complete') {
        let processingDays = resultData.processingDays;
        this.busHandlingId = resultData.busHandlingId;
        this.handerDialog('提交', processingDays);
      }
    },
    /* 修改更新 */
    async updateBusHandling(arg) {
      const { status, resultData } = await updateBusHandling(arg);
      if (status === 'complete') {
        let processingDays = resultData.processingDays;
        this.busHandlingId = resultData.busHandlingId;
        this.handerDialog('修改', processingDays);
      }
    },
    /* 成功后弹框 */
    handerDialog(dialogName, processingDays) {
      Dialog.confirm({
        title: `您的业务办理已${dialogName}成功！`,
        message:
          processingDays !== undefined
            ? `预计在${processingDays}个工作日内完成，请耐心等待，可至“我的办理”中查看办理进度。`
            : '可至“我的办理”中查看办理进度。',
        confirmButtonText: '查看记录',
        confirmButtonColor: '#3C7EF0',
        cancelButtonText: '返回首页',
        cancelButtonColor: '#767E8C',
      })
        .then(() => {
          // 查看记录
          this.setBusinessNews('see');
          this.key2 = +new Date();
          this.formConf = { ...this.formConf, readonly: true };
          this.selectBusHandlingById(this.busHandlingId);
        })
        .catch(() => {
          // 返回首页
          this.$router.push({ path: '/' });
        });
    },
    /* 业务全局变量修改 */
    setBusinessNews(State) {
      this.businessState = State;
      let item = {
        ...this.businessNews,
        businessState: State,
        busHandlingId: this.busHandlingId,
      };
      store.dispatch('user/setBusinessNews', item);
    },
  },
};
</script>

<style lang="less" scoped>
.vant-parser {
  margin: 16px 16px 80px 16px;
  overflow: hidden;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px 15px 0px;
  background: #ffffff;
  width: 100%;
  position: fixed;
  bottom: 0px;

  .btns {
    width: 100%;
    text-align: center;
    margin: 0px 16px 0px 16px;
    /deep/.van-button--normal {
      width: calc(100% / 2 - 10px);
      border-radius: 8px;
    }
  }
}
</style>
